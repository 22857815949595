<template>
  <v-row>
    <v-col cols="12">
      <v-btn color="primary" @click="$emit('unAssignValues')" :disabled="disableUnAssign">
        <v-icon>far fa-chevron-right</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-btn color="primary" @click="$emit('unAssignAllValues')">
        <v-icon>far fa-chevron-double-right</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-btn color="primary" @click="$emit('assignValues')" :disabled="disableAssign">
        <v-icon>far fa-chevron-left</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-btn color="primary" @click="$emit('assignAllValues')">
        <v-icon>far fa-chevron-double-left</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ValueControls",

  props: {
    disableAssign: {
      type: Boolean,
      required: false,
      default: true,
    },

    disableUnAssign: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
