<template>
  <v-row justify="space-between">
    <v-col cols="5">
      <BaseHeadline size="4" class="mb-5">{{ $t("ASSIGN_TABLE.ASSIGNED.VALUES") }}</BaseHeadline>
      <ValueTable
        :items="assignedValues"
        :loading="loading"
        v-model="selection.toUnAssign"
        :field="fields[0]"
      />
    </v-col>

    <v-col cols="2" class="text-center" align-self="center">
      <ValueControls
        @assignValues="assignValues"
        @assignAllValues="assignAllValues"
        @unAssignValues="unAssignValues"
        @unAssignAllValues="unAssignAllValues"
        :disableAssign="selection.toAssign.length === 0"
        :disableUnAssign="selection.toUnAssign.length === 0"
      />
    </v-col>

    <v-col cols="5">
      <BaseHeadline size="4" class="mb-5">
        {{ $t("ASSIGN_TABLE.ASSIGNABLE.VALUES") }}
      </BaseHeadline>
      <ValueTable
        :items="assignableValues"
        :loading="loading"
        v-model="selection.toAssign"
        :field="fields[1]"
      />
    </v-col>
  </v-row>
</template>

<script>
import ValueTable from "@components/AssignTable/partials/ValueTable.vue";
import ValueControls from "@components/AssignTable/partials/ValueControls.vue";
import { xorBy, pull } from "lodash";

export default {
  name: "AssignTable",

  data() {
    return {
      selection: {
        toAssign: [],
        toUnAssign: [],
      },
    };
  },

  components: {
    ValueTable,
    ValueControls,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        assigned: [],
        assignable: [],
      }),
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  computed: {
    assignTableValues: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    assignedValues() {
      return this.assignTableValues.assigned.map((value) => ({
        name: value[this.fields[1]],
        id: value.id,
      }));
    },

    assignableValues() {
      const value = this.assignTableValues.assignable.map((value) => ({
        name: value[this.fields[0]],
        id: value.id,
      }));

      return xorBy(value, this.assignedValues, "id");
    },
  },

  methods: {
    resetSelection() {
      this.selection = {
        toAssign: [],
        toUnAssign: [],
      };
    },

    assignValues() {
      const newValues = [];
      newValues.push(...this.assignedValues.map(({ id }) => id));
      newValues.push(...this.selection.toAssign.map(({ id }) => id));
      this.resetSelection();
      this.$emit("sync", newValues);
    },

    assignAllValues() {
      const newValues = [];
      newValues.push(...this.assignedValues.map(({ id }) => id));
      newValues.push(...this.assignableValues.map(({ id }) => id));
      this.resetSelection();
      this.$emit("sync", newValues);
    },

    unAssignValues() {
      const unassigned = pull(
        this.assignedValues.map(({ id }) => id),
        ...this.selection.toUnAssign.map(({ id }) => id)
      );
      this.resetSelection();
      this.$emit("sync", unassigned);
    },

    unAssignAllValues() {
      this.resetSelection();
      this.$emit("sync", []);
    },
  },
};
</script>
