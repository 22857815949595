<template>
  <v-data-table
    :headers="headers"
    v-bind="$attrs"
    v-on="$listeners"
    class="elevation-2"
    v-model="selection"
    show-select
  />
</template>

<script>
export default {
  name: "ValueTable",

  props: {
    field: {
      type: String,
      required: true,
    },

    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          value: "name",
        },
      ];
    },

    selection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
